import React, { useEffect, useState } from 'react'
import aboutImg from '../../assets/about/homeabout.png'
import contactMobile from '../../assets/homemobilecontact.png'
import { Link, useLocation } from 'react-router-dom'
import './Contact.css'
import axios from 'axios'
import { Toaster, toast } from 'sonner';

const Contact = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const location = useLocation();

    const [message, setMessage] = useState('')

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
    });

    const [errors, setErrors] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        switch (name) {
            case "firstName":
            case "lastName":
                setErrors({
                    ...errors,
                    [name]: /^[A-Za-z]{2,}$/.test(value)
                        ? ""
                        : "Must contain at least 2 letters.",
                });
                break;

            case "email":
                setErrors({
                    ...errors,
                    email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
                        ? ""
                        : "Invalid email format.",
                });
                break;

            case "phone":
                setErrors({
                    ...errors,
                    phone: /^[0-9]{10}$/.test(value)
                        ? ""
                        : "Phone number must be 10 digits.",
                });
                break;

            default:
                break;
        }
    };

    const submitForm = async (e) => {
        e.preventDefault();

        if (formData.firstName === '' || formData.lastName === '' || formData.email === '' || formData.phone === '') {
            toast.error('Please fill all required fields')
            return
        }

        if (errors.firstName != '' || errors.lastName != '' || errors.email != '' || errors.phone != '') {
            toast.error('Please check your details')
            return
        }

        try {
            if (formData.firstName && formData.lastName && formData.email && formData.phone) {

                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/mail/contact`, {
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    email: formData.email,
                    phone: formData.phone,
                    message: message,
                });

                if (response.data.status == 'success') {
                    window.scrollTo(0, 0)
                    toast.success(response.data.message)

                    setFormData({
                        firstName: "",
                        lastName: "",
                        email: "",
                        phone: "",
                    });
                    setMessage('')

                }
                if (response.data.status == 'error') {
                    toast.error(response.data.message)
                }
            }

        } catch (error) {
            console.error('There was an error posting the love letter!', error);
        }
    }

    return (

        <div className='Contact'>
            <div className='ContactMain'>

                <div className='contactContent'>
                    <div className='max-w-[410px] contactContentInner !gap-[20px]'>
                        <div className='font-heading aboutText'>
                            HIT US UP FOR A DISCOVERY CALL
                        </div>

                        <div className='font-bodyone aboutSubText mb-4'>
                            Got questions or just want to chat with us about diamonds? Book a discovery call - We're here to make your brilliance a reality!
                        </div>

                        <Link to='/bookanappointment'>
                            <button className=''>
                                BOOK AN APPOINTMENT
                            </button>
                        </Link>
                    </div>

                    <div className='max-w-[410px] w-full contactContentInner !gap-[20px] sm:!items-start'>
                        <div className='font-heading aboutText'>
                            OR DIRECTLY REACH OUT!
                        </div>

                        <div className='font-bodyone aboutSubText'>
                            <Link to='tel:+918978785151' className='pinkHover'>+91 8978785151 </Link>
                            <Link to='mailto:moheerajewels@gmail.com' className='pinkHover'>moheerajewels@gmail.com</Link>
                            <div className='mt-2'>
                                Door No. 9-2/5, Villa 5, Ektha Highland Park, <br />Near Continental Hospital, Puppalaguda, Gachibowli, <br />Hyderabad - 500032
                            </div>
                        </div>
                    </div>
                </div>

                <div className='HomeAboutImgDiv'>
                    {location.pathname == '/' ?
                        <img src={contactMobile} />
                        :
                        <img src={aboutImg} />
                    }
                </div>

            </div>

            <div className='font-heading aboutText text-center sm:text-left max-w-[300px] sm:max-w-[1030px] mx-auto mt-20 mb-10'>
                OR PUT IN YOUR DETAILS AND WE WILL REACH OUT TO YOU
            </div>


            <div className='BA_container max-w-[1030px] mx-auto'>

                <div>
                    <div className='BA_head'>PERSONAL INFORMATION</div>

                    <div className="personalInfo">
                        {[
                            { label: "FIRST NAME*", name: "firstName" },
                            { label: "LAST NAME*", name: "lastName" },
                            { label: "EMAIL ID*", name: "email" },
                            { label: "PHONE NUMBER*", name: "phone" },
                        ].map(({ label, name }) => (
                            <div key={name} className="relative">
                                <div>{label}</div>
                                <input
                                    className={`input w-[90%] text-[var(--primary)] my-2 font-subheading ${errors[name] ? "border-red-500" : ""
                                        }`}
                                    name={name}
                                    value={formData[name]}
                                    onChange={handleChange}
                                />
                                {/* {errors[name] && ( */}
                                <div className="absolute top-[-24px] right-0 left-0 text-[var(--primary)] text-[10px]">
                                    {formData[name] == '' ? "This field is required." : errors[name]}
                                </div>
                                {/* )} */}
                            </div>
                        ))}
                    </div>
                </div>

                <div>
                    <div className='BA_head'>MESSAGE</div>
                    <input
                        className='input w-[100%] text-[var(--primary)] my-4 mx-auto font-subheading'
                        placeholder=''
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                </div>

                <div className='font-subheading text-[var(--primary)] text-center mt-8'>We typically take 24 -48 hours to respond.</div>

                <button className='w-[200px] mx-auto mb-[-80px] mt-6'
                    onClick={(e) => submitForm(e)}
                >
                    Submit
                </button>
            </div>

            <Toaster position="top-center" />

        </div>
    )
}

export default Contact