import React, { useEffect, useState } from 'react'
import down from '../../assets/icons/down.png'
import virtualwhite from '../../assets/icons/virtual.png'
import virtualpink from '../../assets/icons/virtualpink.png'
import walkinwhite from '../../assets/icons/walkin.png'
import walkinpink from '../../assets/icons/walkinpink.png'
import './BookAppointment.css'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { Toaster, toast } from 'sonner';

const BookAppointment = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [hover, setHover] = useState(false)
    const [hover2, setHover2] = useState(false)

    const [mode, setMode] = useState('')
    const [purpose, setPurpose] = useState('')
    const [specifyNeeds, setSpecifyNeeds] = useState("");

    const needsOptions = {
        "Personalised Shopping Experience": [
            "DISCOVER PRODUCTS",
            "CUSTOM ENGAGEMENT RING",
            "CUSTOM PRODUCT DESIGN"
        ],
        "Customer Care Services": [
            "EXCHANGE/ RETURN",
            "GETTING TO KNOW LAB DIAMONDS"
        ]
    };

    const [priceRange, setPriceRange] = useState("");
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedSlot, setSelectedSlot] = useState(null);
    const timeSlots = [
        "TIME SLOT 1",
        "TIME SLOT 2",
        "TIME SLOT 3",
        "TIME SLOT 4",
        "TIME SLOT 5",
        "TIME SLOT 6",
        "TIME SLOT 7",
        "TIME SLOT 8",
    ];

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
    });

    const [errors, setErrors] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        // Validation logic
        switch (name) {
            case "firstName":
            case "lastName":
                setErrors({
                    ...errors,
                    [name]: /^[A-Za-z]{2,}$/.test(value)
                        ? ""
                        : "Must contain at least 2 letters.",
                });
                break;

            case "email":
                setErrors({
                    ...errors,
                    email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
                        ? ""
                        : "Invalid email format.",
                });
                break;

            case "phone":
                setErrors({
                    ...errors,
                    phone: /^[0-9]{10}$/.test(value)
                        ? ""
                        : "Phone number must be 10 digits.",
                });
                break;

            default:
                break;
        }
    };

    const [additionalInfo, setAdditionalInfo] = useState('');


    const submitForm = async (e) => {

        e.preventDefault();

        if (formData.firstName === '' || formData.lastName === '' || formData.email === '' || formData.phone === '') {
            toast.error('Please fill all required fields')
            return
        }

        if (errors.firstName != '' || errors.lastName != '' || errors.email != '' || errors.phone != '') {
            toast.error('Please check your details')
            return
        }

        try {
            if (formData.firstName && formData.lastName && formData.email && formData.phone) {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/mail/bookappointment`, {
                    mode: mode,
                    purpose: purpose,
                    specifyNeeds: specifyNeeds,
                    priceRange: priceRange,
                    date: selectedDate,
                    time: selectedSlot,
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    email: formData.email,
                    phone: formData.phone,
                    additionalInfo: additionalInfo,
                });

                if (response.data.status == 'success') {
                    window.scrollTo(0, 0)
                    toast.success(response.data.message)

                    setFormData({
                        firstName: "",
                        lastName: "",
                        email: "",
                        phone: "",
                    });
                    setErrors({});
                    setMode("");
                    setPurpose("");
                    setSpecifyNeeds("");
                    setPriceRange("");
                    setSelectedDate("");
                    setSelectedSlot("");
                    setAdditionalInfo("");
                }
                if (response.data.status == 'error') {
                    toast.error(response.data.message)
                }
            }

        } catch (error) {
            console.error('There was an error posting the love letter!', error);
        }
    }


    return (
        <div className='BookAppointment'>
            <div>
                <button className='text-white'>BOOK AN APPOINTMENT</button>
                <div className='text-[10px] text-white text-left px-4 mt-4'><Link to='/' className='pinkHover'>HOME</Link> / BOOK AN APPOINTMENT</div>
            </div>

            <div className='BA_head'>BOOK AN APPOINTMENT</div>

            <div className='BA_container'>
                <div>
                    <div className='BA_head'>1. SELECT A MODE OF APPOINTMENT</div>
                    <div className='flex justify-evenly'>
                        <div className='flex flex-col items-center justify-center gap-4 w-full h-20 cursor-pointer'
                            onMouseEnter={e => setHover(true)}
                            onMouseLeave={e => setHover(false)}
                            onClick={() => { setMode('Walk In') }}
                        >
                            <div className='h-6'>
                                <img src={(hover || mode == 'Walk In') ? walkinpink : walkinwhite} className='max-h-[100%] h-full object-contain' />
                            </div>
                            <div className={(hover || mode == 'Walk In') ? 'pinkHover hover' : 'pinkHover'}>WALK IN</div>
                        </div>
                        <div className='flex flex-col items-center justify-center gap-4 w-full h-20 cursor-pointer'
                            onMouseEnter={e => setHover2(true)}
                            onMouseLeave={e => setHover2(false)}
                            onClick={() => { setMode('Virtual') }}
                        >
                            <div className='h-5'>
                                <img src={(hover2 || mode == 'Virtual') ? virtualpink : virtualwhite} className='max-h-[100%] h-full object-contain' />
                            </div>
                            <div className={(hover2 || mode == 'Virtual') ? 'pinkHover hover' : 'pinkHover'}>VIRTUAL</div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className='BA_head'>2. PURPOSE OF YOUR VISIT</div>
                    <div className='flex justify-evenly gap-16 sm:gap-4 w-full mt-4 flex-col sm:flex-row'>
                        <div>
                            <div onClick={() => { setPurpose("Personalised Shopping Experience"); setSpecifyNeeds(""); }}>
                                <input
                                    type="radio"
                                    name='purpose'
                                    id='PSE'
                                    value="Personalised Shopping Experience"
                                    checked={purpose === "Personalised Shopping Experience"}
                                />
                                <label htmlFor="yes" className='ml-3 cursor-pointer'>PERSONALISED SHOPPING EXPERIENCE</label>
                            </div>

                            <div className={purpose === "Personalised Shopping Experience" ? 'w-full mt-10' : 'w-full mt-10 hidden sm:block'}>
                                <div className='text-lg !font-subheading text-center'>specify your needs</div>

                                <select
                                    className={`input font-subheading lowercase w-[100%] mt-4 text-[var(--primary)] ${purpose !== "Personalised Shopping Experience" ? "opacity-50 cursor-not-allowed" : ""
                                        }`}
                                    value={specifyNeeds}
                                    onChange={(e) => setSpecifyNeeds(e.target.value)}
                                    disabled={purpose !== "Personalised Shopping Experience"}
                                >
                                    {needsOptions["Personalised Shopping Experience"].map((need, index) => (
                                        <option key={index} value={need} className="pinkHover font-body option uppercase">
                                            {need}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div>
                            <div onClick={() => { setPurpose("Customer Care Services"); setSpecifyNeeds(""); }}>
                                <input
                                    type="radio"
                                    name='purpose'
                                    id='CCS'
                                    value="Customer Care Services"
                                    checked={purpose === "Customer Care Services"}
                                />
                                <label htmlFor="yes" className='ml-3 cursor-pointer'>CUSTOMER CARE SERVICES</label>
                            </div>

                            <div className={purpose === "Customer Care Services" ? 'w-full mt-10' : 'w-full mt-10 hidden sm:block'}>
                                <div className='text-lg !font-subheading text-center'>specify your needs</div>

                                <select className={`input font-subheading lowercase w-[100%] mt-4 text-[var(--primary)] ${purpose !== "Customer Care Services" ? "opacity-50 cursor-not-allowed" : ""
                                    }`}
                                    value={specifyNeeds}
                                    onChange={(e) => setSpecifyNeeds(e.target.value)}
                                    disabled={purpose !== "Customer Care Services"}
                                >
                                    {needsOptions["Customer Care Services"].map((need, index) => (
                                        <option key={index} value={need} className="pinkHover font-body option uppercase">
                                            {need}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {/* } */}
                        </div>

                    </div>

                    <div className='flex justify-evenly gap-4 mt-8 items-center'>
                        <div className='font-subheading'>do you have a price range in mind?</div>
                        <input className='input w-[50%] max-w-[250px]'
                            placeholder='INR'
                            value={priceRange}
                            onChange={(e) => setPriceRange(e.target.value)}
                        />
                    </div>
                </div>

                <div>
                    <div className='BA_head'>3. CHOOSE A DATE</div>
                    <input
                        type="date"
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(e.target.value)}
                        className="input w-full mt-4 text-white"
                    />
                </div>

                <div>
                    <div className='BA_head'>4. CHOOSE A TIME</div>
                    <div className="timeSlots my-4">
                        {timeSlots.map((slot, index) => (
                            <div
                                key={index}
                                className={`pinkHover ${selectedSlot === slot ? "hover" : ""}`}
                                onClick={() => setSelectedSlot(slot)}
                            >
                                {slot}
                            </div>
                        ))}
                    </div>
                </div>

                <div>
                    <div className='BA_head'>5. PERSONAL INFORMATION</div>

                    <div className="personalInfo">
                        {[
                            { label: "FIRST NAME*", name: "firstName" },
                            { label: "LAST NAME*", name: "lastName" },
                            { label: "EMAIL ID*", name: "email" },
                            { label: "PHONE NUMBER*", name: "phone" },
                        ].map(({ label, name }) => (
                            <div key={name} className="relative">
                                <div>{label}</div>
                                <input
                                    className={`input w-[90%] text-[var(--primary)] my-2 font-subheading ${errors[name] ? "border-red-500" : ""
                                        }`}
                                    name={name}
                                    value={formData[name]}
                                    onChange={handleChange}
                                />
                                {/* {errors[name] && ( */}
                                <div className="absolute top-[-24px] right-0 left-0 text-[var(--primary)] text-[10px]">
                                    {formData[name] == '' ? "This field is required." : errors[name]}
                                </div>
                                {/* )} */}
                            </div>
                        ))}
                    </div>

                </div>

                <div>
                    <div className='BA_head'>6. ADDITIONAL INFORMATION/ ANYTHING YOU WOULD LIKE US TO KNOW</div>
                    <input
                        className='input w-[100%] !text-left text-[var(--primary)] my-4 mx-auto font-subheading'
                        value={additionalInfo}
                        onChange={(e) => setAdditionalInfo(e.target.value)}
                    />

                </div>

                <button className='w-[200px] mx-auto mb-[-80px] mt-8'
                    onClick={(e) => submitForm(e)}
                >
                    Submit
                </button>
            </div>


            <div className='flex flex-col items-center gap-2 mt-20 text-left'>
                <div className='max-w-[300px]'>
                    THANK YOU FOR CONTACTING US. WE WILL BE IN TOUCH VIA EMAIL AND PHONE. FOR ANY URGENT REQUIRMENT PLEASE GO TO OUR CONTACT US PAGE.
                    <br /><br />Additionally you can browse through our catalogue and pick out the ones you like for reference.
                </div>
                <Link to='/contactus'><button className='w-[160px] mt-4'>contact us</button></Link>
            </div>


            <Toaster position="top-center" />
        </div>
    )
}

export default BookAppointment