import React, { useState, useEffect } from 'react'
import './Checkout.css'
import { useShoppingCart } from '../../context/ShoppingCartContext';
import { Link, useParams, useNavigate } from 'react-router-dom';
import icon from '../../assets/icons/cart.svg'
import axios from 'axios';
import { Toaster, toast } from 'sonner';
import CheckoutItem from './CheckoutItem';

const Checkout = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const { cartTotalPrice, setCartIsOpen, cartItems, formatIndianPrice } = useShoppingCart();

    const [totalPrice, setTotalPrice] = useState();

    const [products, setProducts] = useState([])

    useEffect(() => {

        const getproducts = async () => {
            try {
                const data = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/products/getproducts`)

                console.log(data.data);
                setProducts(data.data);
            }
            catch (error) { console.log('There was an error getting the love letters!', error); }
        }

        getproducts();

    }, [])

    useEffect(() => {
        if (cartTotalPrice) {
            const gst = cartTotalPrice * 0.03;
            setTotalPrice(cartTotalPrice + gst);
        }
    }, [cartTotalPrice]);


    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        country: "",
        address: "",
        city: "",
        state: ""
    });

    const [errors, setErrors] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        country: "",
        address: "",
        city: "",
        state: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        switch (name) {
            case "firstName":
            case "lastName":
            case "country":
            case "address":
            case "city":
            case "state":
                setErrors({
                    ...errors,
                    [name]: /^[A-Za-z]{2,}$/.test(value)
                        ? ""
                        : "Must contain at least 2 letters.",
                });
                break;

            case "email":
                setErrors({
                    ...errors,
                    email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
                        ? ""
                        : "Invalid email format.",
                });
                break;

            case "phone":
                setErrors({
                    ...errors,
                    phone: /^[0-9]{10}$/.test(value)
                        ? ""
                        : "Phone number must be 10 digits.",
                });
                break;

            default:
                break;
        }
    };



    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () => resolve(true);
            script.onerror = () => resolve(false);
            document.body.appendChild(script);
        });
    }


    async function displayRazorpay() {

        if (formData.firstName === '' || formData.lastName === '' || formData.email === '' || formData.phone === '' || formData.country === '' || formData.address === '' || formData.city === '' || formData.state === '' ) {
            toast.error('Please fill all required fields')
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/pay/create-order`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ totalPrice, cartItems })
            });
            return
        }

        if (cartItems.length == 0 || totalPrice == '') {
            toast.error('No Items in your cart')
            return
        }

        if (errors.firstName != '' || errors.lastName != '' || errors.email != '' || errors.phone != '' || errors.country != '' || errors.address != '' || errors.city != '' || errors.state != '') {
            toast.error('Please check your details')
            return
        }

        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

        if (!res) {
            toast.error('Razorpay SDK failed to load. Are you online?');
            return;
        }

        try {
            const amount = totalPrice;
            const firstName = formData.firstName;
            const lastName = formData.lastName;
            const email = formData.email;
            const phone = formData.phone;
            const country = formData.country;
            const address = formData.address;
            const city = formData.city;
            const state = formData.state;

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/pay/create-order`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ amount, currency: 'INR', receipt: 'receipt#1', notes: {} })
            });

            const data = await response.json();
            console.log(data);

            if (!data || !data.id) {
                toast.error('Failed to create Razorpay order');
                return;
            }

            const options = {
                key: process.env.REACT_APP_RAZORPAY,
                amount: data.amount, // Amount in smallest currency unit
                currency: data.currency,
                name: 'Moheera Jewels',
                description: 'Test Transaction',
                image: './watermark.png',
                order_id: data.id, // Use orderId from backend
                // callback_url: `/verify-payment`, // Optional, can handle manually
                handler: function (response) {
                    fetch(`${process.env.REACT_APP_BACKEND_URL}/pay/verify-payment`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            razorpay_order_id: response.razorpay_order_id,
                            razorpay_payment_id: response.razorpay_payment_id,
                            razorpay_signature: response.razorpay_signature,
                            firstName, lastName, email, phone, country, address, city, state, totalPrice, cartItems
                        }),
                    })
                        .then((res) => res.json())
                        .then((data) => {
                            if (data.status === 'ok') {
                                toast.success('Payment successful!');
                                window.location.href = '/';
                                // Redirect to success page
                            } else {
                                toast.error('Payment verification failed');
                            }
                        })
                        .catch((error) => {
                            console.error('Error:', error);
                            toast.error('Error verifying payment');
                        });
                },
                notes: {
                    address: 'Razorpay Corporate Office',
                },
                theme: {
                    color: '#CB919A',
                },
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        } catch (error) {
            console.error('Error in Razorpay integration:', error);
            toast.error('Something went wrong. Please try again.');
        }
    }

    return (

        <div className='Checkout'>

            <div>
                <button className='text-white'>CHECKOUT</button>
            </div>

            <div className='BA_head'>MY CART</div>

            <div className='checkoutContainer'>

                <div className='BA_container !gap-4 !px-5 sm:!px-12'>
                    <div className='BA_head mb-4'>CUSTOMER INFORMATION</div>

                    {[
                        { label: "FIRST NAME*", name: "firstName" },
                        { label: "LAST NAME*", name: "lastName" },
                        { label: "EMAIL ID*", name: "email" },
                        { label: "PHONE NUMBER*", name: "phone" },
                    ].map(({ label, name }) => (
                        <div key={name} className="relative px-4">
                            <div>{label}</div>
                            <input
                                className={`input w-full sm:w-[90%] text-[var(--primary)] my-2 font-subheading ${errors[name] ? "border-red-500" : ""
                                    }`}
                                name={name}
                                value={formData[name]}
                                onChange={handleChange}
                            />
                            {/* {errors[name] && ( */}
                            <div className="absolute top-[6px] right-4 left-0 text-[var(--primary)] text-[10px] text-right">
                                {formData[name] == '' ? "This field is required." : errors[name]}
                            </div>
                            {/* )} */}
                        </div>
                    ))}


                    <div className='BA_head my-4'>DELIVERY DETAILS</div>

                    {[
                        { label: "COUNTRY / REGION*", name: "country" },
                        { label: "ADDRESS*", name: "address" },
                        { label: "CITY*", name: "city" },
                        { label: "REGION / STATE*", name: "state" },
                    ].map(({ label, name }) => (
                        <div key={name} className="relative px-4">
                            <div>{label}</div>
                            <input
                                className={`input w-full sm:w-[90%] text-[var(--primary)] my-2 font-subheading ${errors[name] ? "border-red-500" : ""
                                    }`}
                                name={name}
                                value={formData[name]}
                                onChange={handleChange}
                            />
                            {/* {errors[name] && ( */}
                            <div className="absolute top-[6px] right-4 left-0 text-[var(--primary)] text-[10px] text-right">
                                {formData[name] == '' ? "This field is required." : errors[name]}
                            </div>
                            {/* )} */}
                        </div>
                    ))}

                    <div className='border my-6'></div>

                    <div className='BA_head mb-4'>SHIPPING</div>

                    <div className='flex gap-[20%] items-start px-4'>
                        <input
                            type="radio"
                            name='shipping'
                            id='standard'
                            value="standard"
                            checked
                            className='mt-1'
                        />
                        <div className='flex flex-col gap-3'>
                            <label htmlFor="standard" className='cursor-pointer'>STANDARD SHIPPING</label>

                            <div className='font-subheading'>For express shipping, please <Link className='pinkHover' to='/contactus'>contact us</Link></div>
                        </div>
                    </div>

                    <button className='w-full rounded-none text-white mt-10'
                        onClick={(e) => displayRazorpay()}>
                        CONTINUE TO PAYMENT
                    </button>

                </div>


                <div className='CheckoutCartInfo'>

                    <div className='flex justify-between items-center'>
                        <div className='font-heading text-xl'>ORDER SUMMARY</div>
                        {/* <div className='cursor-pointer' onClick={() => setCartIsOpen(true)}>EDIT CART</div> */}
                    </div>

                    {cartItems.length > 0 ?
                        <div className='flex flex-col'>
                            {cartItems && cartItems.map((item, i) => (
                                <CheckoutItem key={i} item={item} products={products} />
                            ))
                            }
                        </div>
                        :
                        <>
                            <div className='font-subheading text-xl'>
                                Your Shopping Cart is Empty
                            </div>

                            <div className='mt-4'>
                                <Link to='/collection'>Shop Here</Link>
                            </div>

                        </>
                    }

                    {cartItems.length > 0 &&

                        <div className=''>

                            <div className='checkoutCartInfoGrid'>
                                <div>ENTER A PROMO CODE</div>
                                <div className='text-black'>
                                    <input className='input w-full'
                                        placeholder='PROMO CODE' />
                                </div>
                            </div>

                            <div className='checkoutCartInfoGrid'>
                                <div>SUBTOTAL</div>
                                <div className='text-black'>
                                    {formatIndianPrice(cartTotalPrice)}
                                </div>
                                <div>SHIPPING</div>
                                <div className='text-black'>
                                    Free
                                </div>
                                <div>GST</div>
                                <div className='text-black'>
                                    3%
                                </div>
                            </div>

                            <div className='checkoutCartInfoGrid'>
                                <div className='font-heading text-2xl'>TOTAL</div>
                                <div className='text-black'>
                                    {formatIndianPrice(totalPrice)}
                                </div>
                            </div>

                        </div>
                    }

                </div>

            </div>

            <Toaster position="top-center" />

        </div >
    )
}

export default Checkout