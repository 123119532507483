import React, { useEffect } from 'react'
import './ShopCollection.css'
import { Link, useNavigate } from 'react-router-dom'
import one from '../../assets/shopcollection/one.png'
import two from '../../assets/shopcollection/two.png'
import pendant from '../../assets/neck.webp';
import earring from '../../assets/ear.webp';

const ShopCollection = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='ShopCollection'>

            <div className='px-5 sm:px-[72px]'>
                <button className='text-[var(--secondary)] bg-white'>SHOP COLLECTION</button>
                <div className='text-[10px] text-white text-left px-4 mt-4'><Link to='/' className='pinkHover'>HOME</Link> / SHOP COLLECTION</div>
            </div>

            <div className='grid grid-cols-1 sm:grid-cols-2 gap-10 sm:gap-0'>

                <div className='px-5 sm:pl-[72px] sm:pr-0 flex flex-col gap-6  justify-center items-start'>
                    <div className='text-[48px] font-heading'>
                        ON THE MOVE
                    </div>

                    <div className='text-white'>
                        Designed with the modern woman in mind —<br /> confident, bold, and always<br /> On the move.  Jewellery that fits her style perfectly,<br /> from day to night.
                    </div>

                    <Link to='/onthemove' className='SC_shopLink'>
                        DISCOVER THE COLLECTION
                    </Link>
                </div>

                <div className='h-[550px]'>
                    <img src={one} className='object-cover h-full w-full' />
                </div>

                <div className='h-[550px] order-4 sm:order-3'>
                    <img src={two} className='object-cover h-full w-full' />
                </div>

                <div className='px-5 sm:pr-[72px] sm:pl-0 flex flex-col gap-6  justify-center items-end order-3 sm:order-4'>
                    <div className='text-[48px] font-heading'>
                        ESSENTIALS
                    </div>

                    <div className='text-white'>
                        Classic designs that are an essential addition to<br /> your vanity closet. SOmething for every ocassion.
                    </div>

                    <Link to='/onthemove' className='SC_shopLink'>
                        DISCOVER THE COLLECTION
                    </Link>
                </div>

            </div>

            <div className='max-w-[1100px] mx-auto w-full px-5 sm:px-[72px] mt-20 mb-8'>
                <div className='font-heading text-white text-[48px] text-left'>See More</div>
                <div className='flex gap-4'>
                    <Link to='/collection' className='w-full'>
                        <div className='bg-white w-full h-[150px] sm:h-[250px] relative p-1'>
                            <img src={pendant} className='object-cover h-full w-full' />
                            <div className='absolute bottom-4 left-0 right-0 seemoreLink'>EXPLORE NEW pendants</div>
                        </div>
                    </Link>
                    <Link to='/collection' className='w-full'>
                        <div className='bg-white w-full h-[150px] sm:h-[250px] relative p-1'>
                            <img src={earring} className='object-cover h-full w-full' />
                            <div className='absolute bottom-4 left-0 right-0 seemoreLink'>EXPLORE earrings</div>
                        </div>
                    </Link>
                </div>
            </div>

        </div>
    )
}

export default ShopCollection