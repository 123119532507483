import React, { useEffect, useState } from 'react'
import './CustomEngRings.css'
import down from '../../assets/icons/down.png'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { Toaster, toast } from 'sonner';

const CustomEngRings = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [message, setMessage] = useState('')
    const [budget, setBudget] = useState('')
    const [file1, setFile1] = useState('')

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
    });

    const [errors, setErrors] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        switch (name) {
            case "firstName":
            case "lastName":
                setErrors({
                    ...errors,
                    [name]: /^[A-Za-z]{2,}$/.test(value)
                        ? ""
                        : "Must contain at least 2 letters.",
                });
                break;

            case "email":
                setErrors({
                    ...errors,
                    email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
                        ? ""
                        : "Invalid email format.",
                });
                break;

            case "phone":
                setErrors({
                    ...errors,
                    phone: /^[0-9]{10}$/.test(value)
                        ? ""
                        : "Phone number must be 10 digits.",
                });
                break;

            default:
                break;
        }
    };

    const submitForm = async (e) => {
        e.preventDefault();

        if (formData.firstName === '' || formData.lastName === '' || formData.email === '' || formData.phone === '') {
            toast.error('Please fill all required fields')
            return
        }

        if (errors.firstName != '' || errors.lastName != '' || errors.email != '' || errors.phone != '') {
            toast.error('Please check your details')
            return
        }

        const Formdata = new FormData();

        if (file1) Formdata.append("image", file1);
        Formdata.append("firstName", formData.firstName);
        Formdata.append("lastName", formData.lastName);
        Formdata.append("email", formData.email);
        Formdata.append("phone", formData.phone);
        if (budget) Formdata.append("budget", budget);
        if (message) Formdata.append("message", message);

        try {
            if (formData.firstName && formData.lastName && formData.email && formData.phone) {

                const response = await axios.post(
                    `${process.env.REACT_APP_BACKEND_URL}/mail/customrings`,
                    Formdata,
                    { headers: { 'Content-Type': 'multipart/form-data' } }
                );

                if (response.data.status == 'success') {
                    window.scrollTo(0, 0)
                    toast.success(response.data.message)

                    setFormData({
                        firstName: "",
                        lastName: "",
                        email: "",
                        phone: "",
                    });
                    setMessage('')
                    setBudget('')
                    setFile1(null)

                }
                if (response.data.status == 'error') {
                    toast.error(response.data.message)
                }
            }

        } catch (error) {
            console.error('There was an error posting the love letter!', error);
        }
    }


    return (
        <div className='CustomEngRings'>

            <div>
                <button className='text-white'>CUSTOM ENGAGEMENT RINGS</button>
                <div className='text-[10px] text-white text-left px-4 mt-4'><Link to='/' className='pinkHover'>HOME</Link> / SHOP / CUSTOM ENGAGEMENT RINGS</div>
            </div>

            <div className='BA_head'>CUSTOM ENGAGEMENT RINGS</div>

            <div className='BA_container'>

                <div className='font-bodyone sm:text-[18px]'>
                    Moheera stands for creating an experience around the jewellery piece, not just delivering it. If you are a couple searching for the perfect engagement ring, we are excited to offer a private session to explain the 4Cs and various engagement ring settings, allowing you to explore different designs until you find the one that suits you best. We look forward to working closely with you from start to finish, creating your dream ring that is as beautiful as it is meaningful.
                </div>

                <button className='w-fit mx-auto'>Start the process</button>

                <div>
                    <div className='BA_head'>PERSONAL INFORMATION</div>

                    <div className="personalInfo">
                        {[
                            { label: "FIRST NAME*", name: "firstName" },
                            { label: "LAST NAME*", name: "lastName" },
                            { label: "EMAIL ID*", name: "email" },
                            { label: "PHONE NUMBER*", name: "phone" },
                        ].map(({ label, name }) => (
                            <div key={name} className="relative">
                                <div>{label}</div>
                                <input
                                    className={`input w-[90%] text-[var(--primary)] my-2 font-subheading ${errors[name] ? "border-red-500" : ""
                                        }`}
                                    name={name}
                                    value={formData[name]}
                                    onChange={handleChange}
                                />
                                {/* {errors[name] && ( */}
                                <div className="absolute top-[-24px] right-0 left-0 text-[var(--primary)] text-[10px]">
                                    {formData[name] == '' ? "This field is required." : errors[name]}
                                </div>
                                {/* )} */}
                            </div>
                        ))}
                    </div>
                </div>

                <div className='flex justify-between gap-8'>
                    <div className='w-full'>
                        <div className='BA_head'>UPLOAD ANY REFERENCE IMAGES</div>

                        <input
                            onChange={
                                e => setFile1(e.target.files[0])
                            }
                            type="file"
                            accept="image/*"
                            className='input formInput'
                            name='image'
                        />
                    </div>

                    <div className='w-full'>
                        <div className='BA_head'>BUDGET</div>

                        <input className='input w-full text-[var(--primary)] font-subheading mt-[12px]'
                            placeholder='' value={budget}
                            onChange={(e) => setBudget(e.target.value)}
                        />
                    </div>
                </div>


                <div className='mt-6'>
                    <div className='BA_head'>MESSAGE</div>
                    <input
                        className='input w-[100%] text-[var(--primary)] my-4 mx-auto font-subheading'
                        placeholder=''
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                </div>

                <button className='w-[200px] mx-auto mb-[-80px] mt-8'
                    onClick={(e) => submitForm(e)}
                >
                    Submit
                </button>

            </div>


            <div className='flex flex-col items-center gap-2 text-left mt-20'>
                <div>
                    HIT US UP AND LET'S WORK TOGETHER TO <br />CREATE A STUNNING PIECE JUST FOR YOU!
                </div>

                <div className='flex justify-evenly w-full my-10 flex-col sm:flex-row'>
                    <div className='flex flex-col items-center gap-2'>
                        <div className='font-subheading text-white mt-4'>
                            contact us or book a discovery call
                        </div>
                        <Link to='/contactus'><button className='text-white w-[160px]'>contact us</button></Link>
                    </div>

                    <div className='flex flex-col items-center gap-2'>
                        <div className='font-subheading text-white mt-4'>
                            know more about our bespoke process
                        </div>
                        <Link to='/bespokeprocess'><button className='text-white w-[160px]'>bespoke process</button></Link>
                    </div>
                </div>
            </div>

            <Toaster position="top-center" />

        </div>
    )
}

export default CustomEngRings